<template>
  <v-app>
        <v-app-bar
          color="amber lighten-1"
          dark
          app
        >
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

          <v-toolbar-title>Solaranlage</v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
        >
          <v-list
            nav
            dense
          >
            <v-list-item-group
              v-model="group"
              active-class="amber--text text--lighten-1"
            >
              <v-list-item 
                 link
                 to="/">
                <v-list-item-icon>
                  <v-icon>mdi-home-battery</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Batterie</v-list-item-title>
              </v-list-item>

              <v-list-item 
                 link
                 to="/power">
                <v-list-item-icon>
                  <v-icon>mdi-home-lightning-bolt</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Leistung</v-list-item-title>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <v-main>
          <router-view></router-view>
        </v-main>  
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: false,
      group: null,
    }),
    methods: {
    },
    computed: {
    },
  }
</script>

<style lang="scss">
</style>
