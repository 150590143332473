<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn @click="changeChart()">{{ buttonDescription }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <line-chart v-if="chartType === 'line'" :chart-data="datacollection" :options="options" style="position: relative; height: 71vh"></line-chart>
        <bar-chart v-if="chartType === 'bar'" :chart-data="datacollection" :options="options" style="position: relative; height: 71vh"></bar-chart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LineChart from "./LineChart.vue";
import BarChart from "./BarChart.vue";
import {format, subSeconds} from 'date-fns';

export default {
  components: {
    LineChart,
    BarChart,
  },
  data () {
      return {
        chartType: 'bar',
        buttonDescription: 'Liniendiagramm',
        datacollection: {
          labels: [],
          datasets: [
            {
              backgroundColor: 'rgba(229, 57, 53, 0.4)',
              data: []
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                labelString: 'Ladestand Batterie in %',
                display: true
              },
              alignToPixels: true,
              ticks: {
                  min: 0,
                  max: 100,
              }
            }],
          }
        }
      }
    },
    created () {
      this.fetchData()
      this.fillData()
    },
    mounted () {
      this.fillData()
    },
    methods: {
      fillData () {
        var labels = []
        var data = []
        for(let i=30; i>0; i--){
          labels.push(format(subSeconds(new Date(), i), 'H:mm:ss'))
          data.push(this.getRandomInt())
        }

        this.datacollection = {
          labels: labels,
          datasets: [
            {
              backgroundColor: 'rgba(229, 57, 53, 0.4)',
              data: data
            },
          ]
        }
      },
      fetchData () {
        setInterval(
          () => {
              var labels = this.datacollection.labels;
              var data = this.datacollection.datasets[0]['data'];
              labels.push(this.getDateTime())
              data.push(this.getRandomInt())

              if(labels.length >= 30){
                labels.shift()
                data.shift()
              }

              this.datacollection = {
                labels: labels,
                datasets: [
                  {
                    backgroundColor: 'rgba(229, 57, 53, 0.4)',
                    data: data
                  },
                ]
              }
            }
          ,1000);
      },
      getRandomInt () {
        return Math.floor(Math.random() * 101)
      },
      getDateTime () {
        const date = new Date();
        return format(date, 'H:mm:ss');
      },
      changeChart() {
        if(this.chartType === 'line') {
          this.chartType = 'bar'
          this.buttonDescription = 'Liniendiagramm'
        }else{
          this.chartType = 'line'
          this.buttonDescription = 'Balkendiagramm'
        }
      }
    }
  }
</script>