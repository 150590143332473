import Vue from 'vue'
import VueRouter from 'vue-router'
import Battery from '../components/Battery.vue'
import Power from '../components/Power.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Battery',
    component: Battery
  },
  {
    path: '/power',
    name: 'Power',
    component: Power
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
